import React from "react";
import LiveSketchPad from "./LiveSketchPad";

export default function App() {
  return (
    <div>
      <LiveSketchPad />
    </div>
  );
}
